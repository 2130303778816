<template>
	<Confirmation :success="isSuccess()" success-title="confirmation.successful-withdrawal"
		success-subtitle="confirmation.withdrawal-processing" error-title="confirmation.there-is-an-issue"
		error-subtitle="confirmation.try-again"
		:try-again-link="`/payments/withdrawal/${$route.params.walletId}`" />
</template>

<script>
import { mapGetters } from 'vuex';
import Confirmation from '@/components/confirmation/Confirmation';

export default {
	name: 'WithdrawalConfirmation',
	components: {
		Confirmation,
	},
	computed: {
		...mapGetters(['isEmbedded']),
	},
	methods: {
		isSuccess() {
			return this.getModuleStatus() === 'success';
		},
		getModuleStatus() {
			return this.$route.query.status;
		},
	},
};
</script>
